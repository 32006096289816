// @ts-ignore
import ReactDOM from 'react-dom/client'
import { lazy, ReactNode } from 'react'
import { parseConfig } from './shared/config'
const ModuleView = lazy(() => import('./views/module/ModuleView'))
const CardView = lazy(() => import('./views/card/CardView'))
const IdentityView = lazy(() => import('./views/identity/IdentityView'))
const InvestorView = lazy(() => import('./views/investor/InvestorView'))
const InvestorDocumentsView = lazy(
  () => import('./views/investor-documents/InvestorDocumentsView')
)
const AgreementView = lazy(() => import('./views/agreement/AgreementView'))
const DemoView = lazy(() => import('./views/demo/DemoView'))
const CorporateView = lazy(() => import('./views/corporate/CorporateView'))
const OAuthView = lazy(() => import('views/oauth/OAuthView'))
const LandingView = lazy(() => import('views/landing/LandingView'))
const KYCRetryView = lazy(() => import('views/kyc-retry/KYCRetryView'))

const rootEl = document.getElementById('atomic-module-container')
const root = ReactDOM.createRoot(rootEl)
const buildTarget = process.env.REACT_APP_BUILD_TARGET as
  | 'investor'
  | 'demo'
  | 'landing'
  | undefined

// OAuth Flow has been implemented initially to handle redirects inside webview.
// This doesn't work with native app sign-in (AT-1714).
// The <OAuthView /> let us handle redirects in browser for both native app sign-in and web site sign-in.
// But, it's backward incompatible change. Blacklist we have in host application containts `https://cdn.plaid.com`
// which we should remove to enable new OAuth flow implementation.
// For now, there is workaround – checking if `plaidLinkToken` was saved into localStorage.
// See AT-1714 for more details.
// Eventually we need to remove the old way to handle oauth, getting rid of restoring module from localStorage.

const searchParams = new URLSearchParams(window.location.search)
const isPlaidRedirect = Boolean(localStorage.getItem('plaidLinkToken'))
const shouldOpenOAuthView =
  searchParams.has('oauth_state_id') && !isPlaidRedirect

if (shouldOpenOAuthView) {
  root.render(<OAuthView />)
} else if (buildTarget === 'investor') {
  root.render(<InvestorView />)
} else if (buildTarget === 'demo') {
  root.render(<DemoView />)
} else if (buildTarget === 'landing') {
  root.render(<LandingView />)
} else {
  const searchParams = new URLSearchParams(window.location.search)
  const config: ModuleOptions = parseConfig(searchParams)
  const view: View = config.view || config.module || 'module'
  const views: { [key in View]: ReactNode } = {
    module: <ModuleView />,
    card: <CardView />,
    identity: <IdentityView />,
    'investor-documents': <InvestorDocumentsView />,
    agreement: <AgreementView />,
    corporate: <CorporateView />,
    'kyc-retry': <KYCRetryView />,
  }
  root.render(views[view])
}
