import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'
import * as FullStory from '@fullstory/browser'
import * as Datadog from '@datadog/browser-rum'
import * as Sentry from '@sentry/browser'
import useModuleOptions from './useModuleOptions'
import usePostMessageChannel, {
  OneTimeTokenMessage,
} from './usePostMessageChannel'
import createBaseURL from './createBaseURL'
import localStorageHelper from './localStorageHelper'
import merge from 'lodash/merge'
import useFeature from './useFeature'
import ErrorPage from '../components/ErrorPage'
import { DefaultThemeProvider } from './useTheme'

type EntityContextUser<T extends EntityType> = {
  type: T
  id: string
}
type EntityContextParticipant<T extends EntityType> = {
  type: T
  id: string
  accountId: string
}
type EntityContextCorporate<T extends EntityType> = {
  type: T
  id: string
}
export type EntityContext =
  | EntityContextUser<'USER'>
  | EntityContextParticipant<'PARTICIPANT'>
  | EntityContextCorporate<'CORPORATE'>

export interface Session {
  sessionToken: string
  accountStatus: Atomic.AccountStatus
  isOnboardingInProgress: boolean
  identifier: string
  userId: string
  accountId: string
  sleeveId: string
  sipData?: Atomic.SystematicPlan
  sleeveData?: Atomic.Sleeve
  userData?: Atomic.User
  externalAccounts: Atomic.ExternalAccount[]
  customTheme: any
  customText: any
  entity: EntityContext
  base: string
  corporateId?: string
  participantId?: string
}

type Status = 'not-started' | 'pending' | 'started' | 'error'
type RefreshSession = () => Promise<unknown>

const SessionContext = createContext<Session | undefined>(undefined)

const UpgradeSessionContext = createContext<RefreshSession>(() =>
  Promise.resolve(null)
)

const RefreshSessionContext = createContext<RefreshSession>(() =>
  Promise.resolve(null)
)

export default function useSession(): Session {
  const context = useContext(SessionContext)
  if (!context) {
    throw new Error('SessionContext is not defined')
  }
  return context
}

export function useSessionUpgrade() {
  return useContext(UpgradeSessionContext)
}

export function useSessionRefresh() {
  return useContext(RefreshSessionContext)
}

interface SessionProviderProps {
  children: ReactNode
  excludeSleeve?: boolean
  excludeSIP?: boolean
  excludeExternalAccounts?: boolean
  excludeAccount?: boolean
}

export function SessionProvider({
  children,
  excludeSleeve = false,
  excludeSIP = false,
  excludeExternalAccounts = false,
  excludeAccount = false,
}: SessionProviderProps) {
  const options = useModuleOptions()
  const entity = options.entities?.active
  const postMessage = usePostMessageChannel()
  const enabledOnBoardingConfirmErrors = useFeature(
    'dev-enable-onboarding-confirm-errors'
  )
  const base = createBaseURL(
    options.mode,
    options.cluster,
    options._override_api
  )
  const { addListener, send } = usePostMessageChannel()
  const [token, setToken] = useState(options.token)
  const [status, setStatus] = useState<Status>('not-started')
  const [error, setError] = useState<Error>()
  const [session, setSession] = useState<Session>()

  const refreshSession = useCallback(() => {
    if (status !== 'pending') {
      setStatus('pending')
      console.log('session expired')
      send('SESSION_EXPIRED')

      return new Promise((resolve) => {
        const removeListener = addListener<OneTimeTokenMessage>(
          'ONE_TIME_TOKEN',
          (message) => {
            console.log('receiving new oneTimeToken')
            setToken(message.token)
            removeListener()
            resolve(null)
          }
        )
      })
    }

    return new Promise((resolve) => {
      const removeListener = addListener('ONE_TIME_TOKEN', () => {
        removeListener()
        resolve(null)
      })
    })
  }, [status, send, addListener])

  const upgradeSession = useCallback(() => {
    if (session) {
      const e = session.entity
      return fetchUser(base, session, e)
        .then((data) => (!excludeAccount ? fetchAccount(base, data, e) : data))
        .then((data) => (!excludeSleeve ? fetchSleeves(base, data) : data))
        .then((data) => (!excludeSIP ? fetchSIP(base, data) : data))
        .then((data) =>
          !excludeExternalAccounts ? fetchExternalAccounts(base, data) : data
        )
        .then(async (data: any) => {
          const account =
            Array.isArray(data.accountList?.elements) &&
            data.accountList.elements.length > 0
              ? data.accountList.elements[0]
              : null

          const isOnboardingInProgress = !isOnboardingFinished(
            data.user,
            enabledOnBoardingConfirmErrors,
            e
          )
          const session: Session = {
            sessionToken: data.sessionToken,
            userId: data.userId,
            identifier: data.identifier,
            customTheme: data.customTheme,
            customText: data.customText,
            isOnboardingInProgress,
            accountId: account?.account_id,
            sleeveId: data.customIndexingSleeveId,
            accountStatus: account?.account_status,
            externalAccounts: data.externalAccounts ?? [],
            sipData: isOnboardingInProgress && data.sipData,
            sleeveData: isOnboardingInProgress && data.sleeveData,
            userData: isOnboardingInProgress && data.user,
            entity: await fetchEntity(base, data.sessionToken, data.userId, e),
            base,
          }

          setSession(session)
        })
    } else {
      return Promise.resolve()
    }
  }, [
    base,
    session,
    excludeAccount,
    excludeSleeve,
    excludeSIP,
    excludeExternalAccounts,
    enabledOnBoardingConfirmErrors,
  ])

  const startSession = useCallback(
    async (
      sessionData: SessionTokensResponse,
      base: string,
      options: {
        token?: string
        cluster?: Cluster
        theme?: Theme | string
        custom_css?: CustomCssOption
        text?: string
        excludeAccount?: boolean
        excludeSleeve?: boolean
        excludeSIP?: boolean
        excludeExternalAccounts?: boolean
      }
    ): Promise<Session> => {
      includeCustomCSS(
        sessionData,
        options.cluster ?? 'prod',
        options.custom_css
      )

      const e = entity ?? makeEntity(sessionData)
      const values = await Promise.all([
        fetchUser(base, sessionData, e),
        ...(!options.excludeAccount
          ? [fetchAccount(base, sessionData, e)]
          : []),
        ...(!options.excludeSIP ? [fetchSIP(base, sessionData)] : []),
        ...(!options.excludeExternalAccounts
          ? [fetchExternalAccounts(base, sessionData)]
          : []),
        ...(options.theme === 'custom'
          ? [fetchCustomTheme(base, sessionData)]
          : []),
        ...(options.text === 'custom'
          ? [fetchCustomText(base, sessionData)]
          : []),
      ])

      let data = merge(...values)

      if (!options.excludeSleeve) {
        data = await fetchSleeves(base, data)
      }

      const account =
        Array.isArray(data.accountList?.elements) &&
        data.accountList.elements.length > 0
          ? data.accountList.elements[0]
          : null

      const isOnboardingInProgress = !isOnboardingFinished(
        data.user,
        enabledOnBoardingConfirmErrors,
        e
      )
      return {
        sessionToken: data.sessionToken,
        userId: data.userId,
        identifier: data.identifier,
        customTheme: data.customTheme,
        customText: data.customText,
        isOnboardingInProgress,
        accountId: account?.account_id,
        sleeveId: data.customIndexingSleeveId,
        accountStatus: account?.account_status,
        userData: isOnboardingInProgress && data.user,
        sleeveData: isOnboardingInProgress && data.sleeveData,
        sipData: isOnboardingInProgress && data.sipData,
        externalAccounts: data.externalAccounts ?? [],
        entity: await fetchEntity(base, data.sessionToken, data.userId, e),
        base,
      }
    },
    [entity, enabledOnBoardingConfirmErrors]
  )

  const setupFullStory = useCallback(
    (session: Session) => {
      if (process.env.NODE_ENV === 'production') {
        try {
          FullStory.identify(session.entity.id, {
            identifier_str: session.identifier,
          })
          FullStory.event('session', {
            'one-time-token': token,
          })
        } catch (err) {
          console.warn('Failed to identify user (FullStory)')
        }
      }
      return session
    },
    [token]
  )

  // const setupLaunchDarkly = useCallback(
  //   async (session: SessionTokensResponse) => {
  //     if (!process.env.REACT_APP_LD_SDK_CLIENT_SIDE_ID) {
  //       throw new Error('REACT_APP_LD_SDK_CLIENT_SIDE_ID is not defined')
  //     }

  //     const ldUser = {
  //       key: session.userId,
  //       custom: {
  //         tenant_identifier: session.identifier,
  //       },
  //     }

  //     try {
  //       const client = LDClient.initialize(
  //         process.env.REACT_APP_LD_SDK_CLIENT_SIDE_ID,
  //         ldUser
  //       )
  //       await client.waitForInitialization()
  //       setFlags(client.allFlags())
  //     } catch (error) {
  //       console.error(error)
  //       console.warn('LaunchDarkly failed to init')
  //     }

  //     return session
  //   },
  //   []
  // )

  const setupSentry = useCallback((session: Session) => {
    if (process.env.NODE_ENV === 'production') {
      try {
        Sentry.configureScope((scope) => {
          scope.setTag('tenant', session.identifier)
          scope.setTag('entity_type', session.entity.type)
          scope.setUser({
            id: session.entity.id,
          })
        })
      } catch (err) {
        console.warn('Failed to setup scope (Sentry)')
      }
    }
    return session
  }, [])

  const setupDatadog = useCallback((session: Session) => {
    if (process.env.NODE_ENV === 'production') {
      try {
        Datadog.datadogRum.setUser({ id: session.entity.id })
        Datadog.datadogRum.setUserProperty('tenant', session.identifier)
        Datadog.datadogRum.setUserProperty('entity_type', session.entity.type)
        Datadog.datadogRum.startSessionReplayRecording()
      } catch (err) {
        console.warn('Failed to setup session (Datadog)')
      }
    }
    return session
  }, [])

  useEffect(() => {
    let interval: ReturnType<typeof setInterval>
    if (options.custom_css === 'staging') {
      interval = setInterval(() => {
        const link = document.querySelector('#custom-css') as HTMLLinkElement
        const newLink = document.createElement('link')
        newLink.href = link.href
        newLink.id = 'custom-css'
        newLink.rel = 'stylesheet'
        newLink.type = 'text/css'
        newLink.onload = () => document.body.removeChild(link)
        document.body.appendChild(newLink)
        console.log('update custom css')
      }, 2000)
    }
    return () => {
      if (interval) {
        clearInterval(interval)
      }
    }
  }, [options.custom_css])

  useEffect(() => {
    exchangeToken(base, options)
      .then((sessionData) =>
        startSession(sessionData, base, {
          token: options.token,
          theme: options.theme,
          text: options.text,
          custom_css: options.custom_css,
          excludeAccount,
          excludeSleeve,
          excludeSIP,
          excludeExternalAccounts,
        })
      )
      .then(setupFullStory)
      .then(setupSentry)
      .then(setupDatadog)
      .then((session) => {
        setSession(session)
        setStatus('started')
      })
      .catch((error) => {
        setStatus('error')
        setError(error)
      })
  }, [
    options,
    base,
    excludeAccount,
    excludeSleeve,
    excludeSIP,
    excludeExternalAccounts,
    startSession,
    setupFullStory,
    setupSentry,
    setupDatadog,
  ])

  return (
    <UpgradeSessionContext.Provider value={upgradeSession}>
      <RefreshSessionContext.Provider value={refreshSession}>
        <SessionContext.Provider value={session}>
          {(status === 'started' || status === 'pending') && children}
          {status === 'error' && error && (
            <DefaultThemeProvider>
              <ErrorPage error={error} onExit={postMessage.exit} />
            </DefaultThemeProvider>
          )}
        </SessionContext.Provider>
      </RefreshSessionContext.Provider>
    </UpgradeSessionContext.Provider>
  )
}

function isOnboardingFinished(
  user: any,
  enabledOnBoardingConfirmErrors?: boolean,
  entity?: Entity
) {
  if (entity && entity.type !== 'USER') {
    return false
  }
  const isIdentityVerified = enabledOnBoardingConfirmErrors
    ? user.verification_status.identity_verification.code ===
      'IDENTITY_VERIFICATION_SUCCESSFUL'
    : user.verification_status.identity_verification.code !==
      'IDENTITY_VERIFICATION_NOT_STARTED'
  // TODO: remove this as soon as it's clear that we don't need to check docs
  // const isDocumentVerified =
  //   user.verification_status.document_verification.code ===
  //   'DOCUMENT_VERIFICATION_SUCCESSFUL'

  const personaInquiryStarted =
    localStorageHelper.get('personaInquiryStarted') &&
    localStorageHelper.get('personaInquiryStarted') === user.id

  return isIdentityVerified || personaInquiryStarted
}

type SessionTokensResponse = {
  userId: string
  sessionToken: string
  identifier: string
}

async function exchangeToken(base: string, config: ModuleOptions) {
  // restore from localStorage
  const savedSession = localStorageHelper.get(
    'session'
  ) as SessionTokensResponse
  if (savedSession) {
    localStorageHelper.clear('session')
    return Promise.resolve(savedSession)
  }

  // use session token
  if (config.token_type === 'session-token') {
    return {
      userId: config.user_id,
      sessionToken: config.token,
      identifier: config.identifier,
    } as SessionTokensResponse
  }

  // start session using one-time token
  const url = new URL('/auth/session-tokens', base)
  return fetch(url.toString(), {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      token: config.token,
    }),
  })
    .then((rsp) => {
      if (rsp.ok) {
        return rsp.json()
      }

      throw new Error('Unable to start session')
    })
    .then((data) => ({
      userId: data.userId ?? data.user_id,
      corporateId: data.corporateId ?? data.corporate_id,
      participantId: data.participantId ?? data.participant_id,
      sessionToken: data.session_token,
      identifier: data.identifier,
    }))
}

async function fetchUser<
  T extends {
    userId: string
    sessionToken: string
    corporateId?: string
    participantId?: string
  }
>(base: string, data: T, entity?: Entity): Promise<T & { user?: Atomic.User }> {
  // Don't fetch user for participant/corporate entity
  if (entity && entity.type !== 'USER') {
    return data
  }

  // One-time token was created for corporate
  if (!data.userId && data.corporateId) {
    return data
  }

  // One-time token was created for participant
  if (!data.userId && data.participantId) {
    return data
  }

  const url = new URL(`/users/${data.userId}`, base).toString()
  const rsp = await fetch(url, {
    headers: {
      'Atomic-Session': data.sessionToken,
    },
  })
  if (!rsp.ok) {
    throw new Error('Unable to fetch user ' + data.userId)
  }
  const user: Atomic.User = await rsp.json()
  return { ...data, user }
}

type FetchAccountsResponse = { elements: Atomic.Account[] }

async function fetchAccount<T extends { userId: string; sessionToken: string }>(
  base: string,
  data: T,
  entity: Entity
): Promise<T & { accountList?: FetchAccountsResponse }> {
  const searchParams = new URLSearchParams()
  switch (entity.type) {
    case 'USER':
      searchParams.append('user_id', entity.id)
      break
    case 'CORPORATE':
      searchParams.append('corporate_id', entity.id)
      break
    case 'PARTICIPANT':
      throw new Error("Can't fetch accounts for a participant")
  }
  const url = new URL(`/accounts?${searchParams.toString()}`, base).toString()
  const rsp = await fetch(url, {
    headers: {
      'Atomic-Session': data.sessionToken,
    },
  })

  if (!rsp.ok) {
    throw new Error('Unable to fetch account for user ' + data.userId)
  }

  const accountList = (await rsp.json()) as FetchAccountsResponse | undefined

  return {
    ...data,
    accountList,
  }
}

type FetchSleevesResponse = {
  elements: [Atomic.Sleeve]
}

async function fetchSleeves<
  T extends { accountList?: FetchAccountsResponse; sessionToken: string }
>(base: string, data: T): Promise<T & { sleeves?: [Atomic.Sleeve] }> {
  const account =
    data.accountList &&
    Array.isArray(data.accountList?.elements) &&
    data.accountList.elements.length > 0
      ? data.accountList.elements[0]
      : null

  const accountId = account?.account_id

  if (!accountId) {
    return data
  }

  const url = new URL(`/accounts/${accountId}/sleeves`, base).toString()

  const options: any = {
    headers: {
      'Atomic-Session': data.sessionToken,
    },
  }

  const rsp = await fetch(url, options)

  if (!rsp.ok) {
    return data
  }

  const sleeves = (await rsp.json()) as FetchSleevesResponse
  const sleeve = sleeves.elements.find(({ type }) => type === 'CUSTOM_INDEXING')

  return {
    ...data,
    customIndexingSleeveId: sleeve?.sleeve_id,
    sleeveData: sleeve,
  }
}

type FetchSIPResponse = {
  elements: Atomic.SystematicPlan[]
}

async function fetchSIP<T extends { userId: string; sessionToken: string }>(
  base: string,
  data: T
): Promise<T & { sipData?: Atomic.SystematicPlan }> {
  const url = new URL(
    `/systematic-plans?user_id=${encodeURIComponent(data.userId)}`,
    base
  ).toString()

  const options: any = {
    headers: {
      'Atomic-Session': data.sessionToken,
    },
  }

  const rsp = await fetch(url, options)

  if (!rsp.ok) {
    return data
  }

  const userSIPList = (await rsp.json()) as FetchSIPResponse

  return {
    ...data,
    ...(userSIPList?.elements?.[0] && { sipData: userSIPList.elements[0] }),
  }
}

type FetchExternalAccountsResponse = {
  elements: Atomic.ExternalAccount[]
}

async function fetchExternalAccounts<
  T extends { userId: string; sessionToken: string }
>(
  base: string,
  data: T
): Promise<T & { externalAccounts?: Atomic.ExternalAccount[] }> {
  const url = new URL(
    `/external-accounts?user_id=${data.userId}`,
    base
  ).toString()

  const rsp = await fetch(url, {
    headers: {
      'Atomic-Session': data.sessionToken,
    },
  })

  if (!rsp.ok) {
    return data
  }

  const externalAccounts = (await rsp.json()) as FetchExternalAccountsResponse

  return {
    ...data,
    externalAccounts: externalAccounts.elements,
  }
}

async function fetchCustomTheme<T extends { identifier?: string }>(
  base: string,
  data: T
): Promise<T> {
  const domain =
    process.env.REACT_APP_CUSTOMIZE_DOMAIN ||
    'https://module.cdn.atomicvest.com'
  const env = 'production' // for now just production version
  const rsp = await fetch(
    `${domain}/custom-theme/${env}/${data.identifier}.json`
  )

  if (!rsp.ok) {
    return data
  }

  const customTheme = await rsp.json()

  return {
    ...data,
    customTheme,
  }
}

async function fetchCustomText<T extends { identifier?: string }>(
  base: string,
  data: T
): Promise<T> {
  const domain =
    process.env.REACT_APP_CUSTOMIZE_DOMAIN ||
    'https://module.cdn.atomicvest.com'
  const env = 'production' // for now just production version
  const rsp = await fetch(
    `${domain}/custom-text/${env}/${data.identifier}.json`
  )

  if (!rsp.ok) {
    return data
  }

  const customText = await rsp.json()

  return {
    ...data,
    customText,
  }
}

function includeCustomCSS<T extends { identifier?: string }>(
  data: T,
  cluster: Cluster,
  custom_css?: CustomCssOption
) {
  const link = document.querySelector('#custom-css') as HTMLLinkElement
  const domain =
    process.env.REACT_APP_CUSTOMIZE_DOMAIN ||
    'https://module.cdn.atomicvest.com'
  if (link && custom_css) {
    if (custom_css === 'local') {
      link.href = `/custom-css.css`
    } else if (data.identifier) {
      link.href = `${domain}/custom-css/${cluster}/${custom_css}/${data.identifier}.css`
    }
  }
  return data
}

async function fetchEntity(
  base: string,
  sessionToken: string,
  userId: string,
  entity?: Entity
): Promise<EntityContext> {
  if (entity) {
    switch (entity.type) {
      case 'USER':
        return fetchUserEntityContext(entity)
      case 'PARTICIPANT':
        return fetchParticipantEntityContext(entity, base, sessionToken)
      case 'CORPORATE':
        return fetchCorporateEntityContext(entity)
    }
  } else {
    const userEntity: EntityContextUser<'USER'> = {
      type: 'USER',
      id: userId,
    }
    return userEntity
  }
}

async function fetchUserEntityContext(entity: Entity) {
  const userEntity: EntityContextUser<'USER'> = {
    type: 'USER',
    id: entity.id,
  }
  return userEntity
}

async function fetchParticipantEntityContext(
  entity: Entity,
  base: string,
  sessionToken: string
) {
  const url = new URL(`/participants/${entity.id}`, base).toString()
  const rsp = await fetch(url, {
    headers: {
      'Atomic-Session': sessionToken,
    },
  })
  const data: Atomic.Participant = await rsp.json()
  const participantEntity: EntityContextParticipant<'PARTICIPANT'> = {
    type: 'PARTICIPANT',
    id: entity.id,
    accountId: data.account_id,
  }
  return participantEntity
}

async function fetchCorporateEntityContext(entity: Entity) {
  const corporateEntity: EntityContextCorporate<'CORPORATE'> = {
    type: 'CORPORATE',
    id: entity.id,
  }
  return corporateEntity
}

function makeEntity(data: {
  userId?: string
  corporateId?: string
  participantId?: string
}): Entity {
  if (data.userId) {
    return {
      id: data.userId,
      type: 'USER',
    }
  }

  if (data.corporateId) {
    return {
      id: data.corporateId,
      type: 'CORPORATE',
    }
  }

  if (data.participantId) {
    return {
      id: data.participantId,
      type: 'PARTICIPANT',
    }
  }

  throw new Error('Unknown entity type')
}
